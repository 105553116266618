import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { ContentfulImageProp } from '@/types';
import Helpers from '@/utils/helpers';

interface AboutImageComponentProps {
  image: ContentfulImageProp;
  alt: string;
}
export const AboutImage: React.FC<AboutImageComponentProps> = ({
  image,
  alt,
}) => (
  <GatsbyImage
    image={Helpers.displayDifferentResolutions(image)}
    alt={alt || ``}
  />
);

export const query = graphql`
  fragment aboutSectionImageDataFragment on ContentfulAsset {
    wide: gatsbyImageData(
      width: 1504
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    large: gatsbyImageData(
      width: 1136
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    desktop: gatsbyImageData(
      width: 960
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    tablet: gatsbyImageData(
      width: 528
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    mobile: gatsbyImageData(
      width: 716
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
  }

  fragment aboutHeaderImageDataFragment on ContentfulAsset {
    wide: gatsbyImageData(
      width: 3168
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    large: gatsbyImageData(
      width: 2432
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    desktop: gatsbyImageData(
      width: 2080
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    tablet: gatsbyImageData(
      width: 1216
      outputPixelDensities: [0.2, 0.25, 0.3, 0.35, 0.4]
    )
    mobile: gatsbyImageData(
      width: 1152
      outputPixelDensities: [0.2, 0.3, 0.4, 0.45, 0.5]
    )
  }
`;
