import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContentfulImageProp } from '@/types';
import Helpers from '@/utils/helpers';

interface PeopleImageComponentProps {
  image: ContentfulImageProp;
  alt: string;
}
export const PeopleImage: React.FC<PeopleImageComponentProps> = ({
  image,
  alt,
}) => (
  <GatsbyImage
    image={Helpers.displayDifferentResolutions(image)}
    alt={alt || ``}
  />
);
