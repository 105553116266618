import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface IContentfulRawToComponentProps {
  raw: string;
  options?: any;
}

const ContentfulRawToComponent: React.FC<IContentfulRawToComponentProps> = ({
  raw,
  options = {},
}) => {
  const defaultOptions = {
    renderText: (text) =>
      text.split(`\n`).reduce(
        (children, textSegment, index) => [
          ...children,
          // eslint-disable-next-line react/no-array-index-key
          index > 0 && <br key={index} />,
          textSegment,
        ],
        [],
      ),
  };

  return (
    <>
      {documentToReactComponents(JSON.parse(raw), {
        ...defaultOptions,
        ...options,
      })}
    </>
  );
};

// hyperlink

export default ContentfulRawToComponent;
