import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StaffProps } from '@/types';
import Helpers from '@/utils/helpers';
import Reveal from '@/components/reveal/Reveal';

interface IStaffItemProps {
  staff: StaffProps;
}

const StaffItem: React.FC<IStaffItemProps> = ({ staff: _staff }) => {
  const windowWidth = Helpers.getWindowInnerWidth();
  const isMobile = windowWidth <= Helpers.window.breakpoint.tablet - 1;

  const bookButtonText = (): string => (isMobile ? `book` : `Book a Meeting`);

  return (
    <Reveal className={`staff ${_staff.countryCode}`}>
      <div className="staff-details">
        <div className="image-container">
          <GatsbyImage image={getImage(_staff.photo.gatsbyImageData)} alt="" />
        </div>

        <p className="name">{`${_staff.firstName} ${_staff.lastName}`}</p>

        <div className="staff-info">
          <p className="position">
            {_staff.position?.title || _staff?.designation || ``}
          </p>

          {_staff.phoneNumber && (
            <a className="phone" href="tel:+358 50 313 9492">
              {_staff.phoneNumber}
            </a>
          )}

          <a className="email" href={`mailto:${_staff.emailAddress}`}>
            {_staff.emailAddress}
          </a>
        </div>
      </div>
      {_staff.calendlyUrl && (
        <div className="action">
          <a
            href={`${_staff.calendlyUrl}`}
            className="link"
            target="_blank"
            rel="noreferrer"
            aria-label={`Book a meeting with ${_staff.firstName} ${_staff.lastName}`}
          >
            {bookButtonText()}
          </a>
        </div>
      )}
      <div className="action">
        <a
          href={`${_staff.linkedInProfile}`}
          className="link"
          target="_blank"
          rel="noreferrer"
          aria-label={`View ${_staff.firstName} ${_staff.lastName}'s LinkedIn Profile`}
        >
          LinkedIn
        </a>
      </div>
    </Reveal>
  );
};

export default StaffItem;
